var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "products category-leaflet" },
    [
      _c("Breadcrumb", { attrs: { items: _vm.breadcrumbs } }),
      _c("category-block", {
        staticClass: "category-block category-block-1",
        attrs: { target: _vm.category, position: "position1" }
      }),
      _c(
        "v-container",
        { staticClass: "py-0" },
        [
          _c("CategoryTitle", {
            key: _vm.category.categoryId,
            attrs: {
              category: _vm.category,
              selectedCategory: _vm.selectedCategory,
              count: _vm.count
            }
          })
        ],
        1
      ),
      _c(
        "v-container",
        [
          !_vm.categoryId
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.category.children, function(subCategory) {
                      return _c(
                        "v-col",
                        {
                          key: subCategory.categoryId,
                          attrs: { cols: "6", sm: "4", md: "3", lg: "2" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                outlined: "",
                                height: "100%",
                                to: {
                                  name: "Category",
                                  params: {
                                    pathMatch: _vm.category.slug
                                  },
                                  query: { categoryId: subCategory.categoryId }
                                }
                              }
                            },
                            [
                              _c("v-img", {
                                staticClass: "center-img mt-3 mx-3",
                                attrs: {
                                  contain: "",
                                  src: _vm.$ebsn.meta(
                                    subCategory,
                                    "category_info.ICON",
                                    _vm.$ebsn.meta(
                                      subCategory,
                                      "category_info.THUMB"
                                    )
                                  ),
                                  alt: subCategory.name,
                                  height: "80"
                                }
                              }),
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "category-block-title primary--text"
                                },
                                [_vm._v(_vm._s(subCategory.name))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c("h3", [_vm._v("Tutti i prodotti")]),
                  _c("ProductListGrid", {
                    key: _vm.category.categoryId,
                    staticClass: "mt-5",
                    attrs: {
                      fluid: _vm.$vuetify.breakpoint.xsOnly,
                      parentCategoryId: _vm.category.categoryId,
                      hidePromoFilter: true,
                      hideFilterString: "Categorie,Main Term"
                    },
                    on: { productsCount: _vm.updateCount }
                  })
                ],
                1
              )
            : _c("ProductListGrid", {
                key: _vm.category.categoryId,
                attrs: {
                  fluid: _vm.$vuetify.breakpoint.xsOnly,
                  parentCategoryId: _vm.category.categoryId,
                  categoryId: _vm.categoryId,
                  hidePromoFilter: true,
                  hideFilterString: "Categorie,Main Term"
                },
                on: { productsCount: _vm.updateCount }
              })
        ],
        1
      ),
      _c("category-block", {
        staticClass: "category-block category-block-2",
        attrs: { target: _vm.category, position: "position2" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }